
.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.imageContainer {
    height: 300px;
    background-color: red;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    border-radius: 10%;
    z-index: 10;
}


.infoContainer {
    background-color: #181817;
    width: 400px;
    height: 375px;
    border-radius: 10%;
    display: flex;
    justify-content: center;
     align-items: center;
     flex-direction: column;
     position: absolute;
     position: relative;
     bottom: 10vh;
     z-index: 1;
     padding-top: 3vh;



     h1 {
        color: white;
        font-size: 24px;
        font-family: Poppins;
        font-weight: bold;
        
    }


h2 {
    color: white;
    font-size: 24px;
    font-family: 'Open Sans Variable', sans-serif;
    font-weight: lighter;
}

.socials {
    display: flex;
    color: white;
    position: relative;
    top: 4vh;
    width: 7vw;
justify-content: space-evenly;

    
    
}


.info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.icon {
border-radius: 15px;
color: #E1E1E1 !important;
}


     
}





