.container {
  
    
}


.searchInput {
    padding: 10px;
    border-radius: 10px;
    background-color: #1A1B1D;
    width: 40vw;
}
