.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky; /* This will "stick" the element at the top */
    top: 0; /* Stick to the top of the viewport */
    width: 100%; /* Limit width to keep it within normal layout */
    z-index: 10; /* Optional: Ensure it stays above other content */
}


.image {
    height: 30px;
    margin-right: 10px;
    
}

.wrapper {
    width: 50%;
    background-color: #F3F2F3;
    border-radius: 30px;
    padding: 50px;
    height: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;


    h3 {
        font-family: 'Poppins';
        font-weight: bold;
        font-size: 20px;
        align-self: left;
    }
}



.nav {
display: flex;
justify-content: space-between;
// background-color: orange;
width: 100%;
padding: 50px;

}


.buttonContainer {
    display: flex;
    justify-content: space-evenly;

    button {
        margin-right: 10px;
    }
}


.leftLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}
