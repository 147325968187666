.card {
    width: 100%;
    height: 100vh;
    margin: auto;
    /* box-shadow: 0px 35px 70px 0px rgba(155, 163, 125, 0.65), 0px 30px 40px -10px rgba(355, 100, 124, 0.55); */
background-image: url('/public/homepagenew.png') !important;
    /* background-color: white; */
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }


@keyframes wave {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}




  
  @media screen and (max-width: 512px) {
    .card {
      width: 100%;
      /* height: 100vh */
      border-radius: 0;
      box-shadow: none;
      
    }
  }
  
  .header {
    display: flex;
    padding: 48px;
    
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin-top: 50px;
    border-radius: 20px;

    
  }
  
  .logo {
    font-weight: bold;
    font-size: 1.5em;
    transition: opacity 0.05s ease;
  }
  
  .logo:hover {
    opacity: 0.75;
  }
  
  .social {
    display: flex;
  
    
  }
  
  .social a {
    display: inline-block;
    margin-right: 12px;
    transition: opacity 0.05s ease;
  
  }
  
  .social a:last-child {
    margin-right: 0;
  }
  
  .social a:hover {
    opacity: 0.75;
  }
  
  .social .icon {
    width: 18px;
    fill: #fff;
  }
  
  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:relative;
    bottom: 50px;
    
  }
  
  .content .title-holder {
    color: #fff;
    text-align: center;
    margin-bottom: 24px;
  }


  
  @media screen and (max-width: 768px) {
    .content .title-holder {
      max-width: 80%;
    }
  }

  .flashlight {
    position: absolute;
    width: 200px; /* Adjust the size of the flashlight */
    height: 200px; /* Adjust the size of the flashlight */
    border-radius: 50%;
    background: radial-gradient(circle at 50% 50%, transparent 100px, #1B1A1B 200px);
    mix-blend-mode: exclusion;
    pointer-events: none;
  }


.register_title{
  padding-top: 50px;
margin-bottom: 20px;
padding-bottom: 9px;
}
  
  .content .cta {
    min-width: 64px;
    padding: 16px 24px;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    transform: none;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
    cursor: not-allowed
  }
  
  .content .cta:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
  }
  
  .footer {
    display: flex;
    flex-direction: row;
    padding: 48px;
    justify-content: center;
    color: gray;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  
  a.underlined:hover {
    border-bottom: 1px dotted #fff;
  }
  




.landing_container {
  position: relative;
  width: 100%;
  height: 100vh;
  
}


.landing_container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.landing_container video:nth-child(2) {
  mix-blend-mode: screen;
}


.focus-in-expand {
  font-size: 90px;
	color: white !important;
  font-family: Bowlby One Sc;
  -webkit-animation: focus-in-expand 5.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-expand 5.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}



 @-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
