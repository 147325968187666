.swiper {
  width: 100%;
  height: 100%;
cursor:grab;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}


.image-container {
  position: relative;
  width: 300px; 
  height: 250px; 
  /* margin: 10px; */
  

  
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}

.image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0, 0.2);
  color: white;
  padding: 10px;
  /* width: 100%; */
  /* font-size: 8px; */
}




.opacity-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); /* Linear gradient background */
  transition: opacity 0.3s ease-in-out; /* Opacity transition */
}

.image-container:hover .opacity-layer {
  opacity: 0; /* Remove opacity on hover */
}
