@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,700;0,800;1,600&display=swap');
@import url('https://fonts.cdnfonts.com/css/back-to-black-demo');

html ,
body,
#root,
.app {
  font-family: 'Rubik', sans-serif;
  height:100vh;
  /* background-image: url('./../public/HomeBackground.png'); */
/* background-color: #1B1A1B; */

background-color: #111315;
/* background-color: #D9D9D9; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

};



.app {
  /* background-image: url('./../public/HomeBackground.png'); */
}





.box-body{
  &::before{
    content:"";
    position:absolute;
    /* background:linear-gradient(112deg, #f930f9, #0f938f); */
    /* background-color: black; */
    width:500px;
    height:350px;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
  }
}

.boxes{
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:100vh;
  background:white;
  filter: blur(10px) contrast(18);
  mix-blend-mode: screen;
}
.box{
  width:50px;
  height:50px;
  border-radius:100%;
  background-color:black;
  

}

.box:nth-child(odd){
  animation: spinLeft 2.5s 0.2s linear both infinite;
}
.box:nth-child(even){
    animation: spinRight 1s 0.4s linear both infinite;
}
.box:nth-child(2n){
   animation: pulse 2s ease-in-out 0.1s infinite;
}
.box:nth-child(4n){
  animation-direction:reverse;
}
@keyframes pulse{
  50%{transform: scale(1.8)}
  70%{transform: scale(0.6)
  }
}

@keyframes spinLeft{
  from{
    transform: rotate(0deg) translate(50px);
  }
  to{
    transform: rotate(360deg) translate(50px);
  }
}
@keyframes spinRight{
  from{
    transform: rotate(360deg) translate(50px);
  }
  to{
    transform: rotate(0deg) translate(50px);
  }
}









/* Search inputs */
.search-input {
  width: 900px
}


@media screen and (max-width: 768px) {
  .search-input {
      width: 700px;
  }
}


@media screen and (max-width: 500px) {
  .search-input {
    width: 350px
  }
}






/* masonry layout */

.my-masonry-grid {
  display: grid;
  block-size: 250px;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: masonry;
  align-tracks: end, start, end, space-between;

}

.my-masonry-grid_column {
  display: grid;
  block-size: 250px;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: masonry;
  align-tracks: end, start, end, space-between;
}


