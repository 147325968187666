
.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .dropzone.active {
    border-color: #2c2e37;
  }


