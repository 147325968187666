.container {
  width: 100%;
  height: 150px; /* Adjust as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #1f1f1f; /* Dark background color */
  padding: 0 20px; /* Add padding for spacing */
}

.logoContainer {
  display: flex;
  align-items: center;
}

.navContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.feedLabel {
  padding: 0 20px;
  h1 {
      font-weight: bold;
      font-size: 18px;
      color: white;
  }
}

.linkContainer {
  margin-left: 20px;

  .link {
      text-decoration: none;
      color: gray;
      margin-right: 20px;
      transition: color 0.3s ease, border-bottom 0.3s ease;

      &:hover, &:focus, &:active {
          color: white;
          border-bottom: 2px solid #FF6347;
          cursor: pointer;
      }

      &.active {
          color: #FF6347;
          border-bottom: 2px solid #FF6347;
      }
  }
}

.searchBar {
  margin-left: 20px;
  
}

.rightContainer {
  // display: flex;
  align-items: center;

  .publishButton {
      margin-right: 20px;
  }
}



.linkContainer {
  margin-left: 20px;
  display: flex;
  align-items: center;

  .link {
      display: flex;
      align-items: center;
      color: gray;
      margin-right: 20px;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover, &:focus, &:active {
          color: white;
      }

      &.active {
          color: #FF6347;
      }

      .linkContent {
          display: flex;
          align-items: center;
      }

      .icon {
          margin-right: 8px; /* Adjust space between icon and label */
          display: flex;
          align-items: center;
          color: white !important;
          font-size: 22px;
      }

      .label {
          font-size: 18px; /* Adjust text size as needed */
      }
  }
}



.avatarContainer {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  content: "''";
  background-color: orange;
}