
.masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-flow: dense;
  grid-gap: 50px;
}

.masonry-item {
  margin-bottom: 16px;
}