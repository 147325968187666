.container {
    height:100vh;
    background-color: white;
};


.img {
    height: 14%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25), /* Main shadow */
    0px 6px 6px rgba(0, 0, 0, 0.15);     /* Soft secondary shadow */
transition: transform 0.2s ease-in-out;
margin-bottom: 20px;
    
}


.heroContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    text-align: center;





.textContainer {
    // padding: 50px;
}




    h1 {
        font-size: 63px;
        font-family: 'Poppins';
        font-weight: bold;
        line-height: 90%;
    }

    @media (max-width: 756px) {
        h1 {
            font-size: 25px;
        }
    }



    .textDescription {
        padding: 10px;
        margin-top: 10px;
    }

    h4 {
        font-size: 20px;
        
        color: lightgray;
    }



.button {
    border:solid red 2px;
    padding: 10px;
    border-radius: 25px;
    margin: 10px;
}

.buttonContainer {
    display: flex;
    
}


    .buttonContainer button:nth-child(1) {
        background-color: black;
        border: none;
        color: white;
        font-family: 'Poppins';
        padding: 15px;
    }


    .buttonContainer button:nth-child(2) {
        background-color: none;
        border: solid gray 1px;
        font-family: 'Poppins';
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
