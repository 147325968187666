.profilePic {
    width: 60%;
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .profileContainer {
    display: flex;
    margin: 20px;
  }
  @media only screen and (max-width: 768px) {
    .profileContainer {
      flex-direction: column-reverse;
    }
  };
/*! EDIT PROFILE SCREEN */


.editProfile_wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
 

 
}

  .formField {
    display: flex;
    flex-direction: column;
    color: white;
    
    padding-top: 50px;
  }

  .text_input {
    box-sizing: content-box;
    background: transparent;
    /* margin: 30px; */
    width: 150%;
    padding: 0.75rem;
    color: rgb(73, 80,87);
    border-radius: 0.375rem;
    display: flex;

align-self: center;
    
   
  }



.form_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E8E4D8 !important;



}


.form_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
